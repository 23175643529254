import React, { useState, useEffect, KeyboardEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Tab, Nav } from 'react-bootstrap';
import { AppDispatch, RootState } from 'store/store';
import { fetchSearch as fetchAllCollections } from '../../store/collections/collections.slice';
import { fetchSearch as fetchAllUsers } from '../../store/users/users.slice';
import { Collection } from 'api/collections/types';
import { User } from 'api/users/types';
import { OrderBy as OrderByCollections } from '../../api/collections/enums';
import { OrderBy as OrderByUsers } from '../../api/users/enums';
import { shortenText } from 'utils/helpers/formatHelper';

const SearchBox: React.FC<{ search: boolean }> = ({ search }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [query, setQuery] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('collections');
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const {searchCollections, isLoadingSearch: isLoadingCollections } = useSelector((state: RootState) => state.collections);
  const {searchUsers, isLoadingSearch: isLoadingUsers } = useSelector((state: RootState) => state.users);
  const [loading, setLoading] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search && inputRef.current) {
      inputRef.current.focus();
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      if (query.length >= 1) {
        switch(activeTab){
          case 'collections':
            dispatch(fetchAllCollections({ query: query, order_by: OrderByCollections.NAME }))
            .finally(() => setLoading(false));
            break;
          default: // 'users':
            dispatch(fetchAllUsers({
              params: {
                query: query,
                order_by: OrderByUsers.NAME,
              }
            })).finally(() => setLoading(false));
        }
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [query, activeTab, dispatch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setSelectedIndex(-1);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if(results){
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          e.key === 'ArrowDown'
            ? Math.min(prevIndex + 1, results.length - 1)
            : Math.max(prevIndex - 1, 0)
        );
      }
      if (e.key === 'Enter' && selectedIndex >= 0) {
        const identifier = activeTab === 'collections' ? (results[selectedIndex] as Collection).slug : (results[selectedIndex] as User).username;
        handleRedirect(identifier);
      }
    }
  };

  const handleRedirect = (identifier: string) => {
    const url = activeTab === 'collections' ? `/collection/${identifier}` : `/profile/${identifier}`;
    window.location.href = url;
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    setQuery('');
    setSelectedIndex(-1);
    if (search && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const results = activeTab === 'collections' ? searchCollections?.data : searchUsers?.data;

  return (
    <div className="w-100" style={{ margin: 'auto',  }} onKeyDown={handleKeyDown}>
      <div className="position-relative">
        <input
          ref={inputRef}
          type="text"
          className="form-control bounce"
          placeholder="Search for collections or users..."
          value={query}
          onChange={handleSearch}
          style={{ transition: 'all 0.3s ease-in-out' }}
          maxLength={100}
          autoComplete='off'
        />
        {query && (
          <button
            type="button"
            onClick={handleClear}
            className="btn-close position-absolute"
            aria-label="Close"
            style={{ top: '50%', right: '10px', transform: 'translateY(-50%)' }}
          ></button>
        )}
      </div>
      {query && (
        <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k || 'collections')}>
          <Nav variant="tabs" className="mt-2">
            <Nav.Item>
              <Nav.Link eventKey="collections">Collections</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="users">Users</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="collections">
              {loading ? (
                <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <ListGroup.Item key={index} style={{ cursor: 'pointer' }}>
                      <div className="placeholder-glow">
                        <span className="placeholder col-2 me-2"></span>
                        <span className="placeholder col-4"></span>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : searchCollections && searchCollections.data.length > 0 ? (
                <ListGroup style={{maxHeight: '400px', overflowY: 'auto'}}>
                  {searchCollections.data.map((item: Collection, index: number) => (
                    <ListGroup.Item
                      key={item.id}
                      onClick={() => handleRedirect(`${item.slug}`)}
                      active={index === selectedIndex}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={item.logo_permalink} alt={item.slug} className="avatar avatar-sm-sm shadow-md rounded-pill me-2"  />
                      <span className="text-muted small" title={item.name}>{shortenText(item.name, 26, 26)}</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <section className="section pt-4 pb-2">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-md-12 text-center">
                        <h5 className="mb-4">Collection not found</h5>
                        <p className="text-muted">Browse interesting photos in personalized collections and discover new and interesting recommendations along the way.</p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="users">
              {loading ? (
                <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <ListGroup.Item key={index} style={{ cursor: 'pointer' }}>
                      <div className="placeholder-glow">
                        <span className="placeholder col-2 me-2"></span>
                        <span className="placeholder col-4"></span>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : searchUsers && searchUsers.data.length > 0 ? (
                <ListGroup style={{maxHeight: '400px', overflowY: 'auto'}}>
                  {searchUsers.data.map((item: User, index: number) => (
                    <ListGroup.Item
                      key={item.id}
                      onClick={() => handleRedirect(`${item.username}`)}
                      active={index === selectedIndex}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={item.profile_photo_url} alt={item.username} className="avatar avatar-sm-sm shadow-md rounded-pill me-2" />
                      <span className="text-muted small" title={item.name}>{shortenText(item.name, 26, 26)}</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <section className="section pt-4 pb-2">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-md-12 text-center">
                        <h5 className="mb-4">User not found</h5>
                        <p className="text-muted">Try again...</p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </div>
  );
};

export default SearchBox;