import React, { useState } from "react";
import { useDisconnect } from "wagmi";
import { Button, Spinner } from "react-bootstrap";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAppContext } from "context/AppContextProvider";

export default function ConnectButton() {
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const {
    isWalletConnecting,
    isWalletConnected,
    isAuthenticated,
  } = useAppContext();

  const label = isWalletConnected ? "Disconnect" : (isWalletConnecting ? "Connecting" : "Connect");

  async function openModal() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  function onClick() {
    if (!isWalletConnected) openModal();
    else disconnect();
  }

  return !isAuthenticated && (
    <Button onClick={onClick} disabled={loading || isWalletConnecting}>
      {loading ? <Spinner color="white" /> : label}
    </Button>
  );
}