import { combineReducers } from '@reduxjs/toolkit';
import collectionsSlice from './collections/collections.slice';
import tokensSlice from './tokens/tokens.slice';
import slidersSlice from './sliders/sliders.slice';
import influencersSlice from './influencers/influencers.slice';
import usersSlice from './users/users.slice';
import socialPlatformsSlice from './social_platforms/social_platforms.slice';
import categoriesSlice from './categories/categories.slice';
import statsSlice from './stats/stats.slice';
import authSlice from './auth/auth.slice';

const rootReducer = combineReducers({
    auth: authSlice,
    collections: collectionsSlice,
    tokens: tokensSlice,
    sliders: slidersSlice,
    influencers: influencersSlice,
    users: usersSlice,
    socialPlatforms: socialPlatformsSlice,
    categories: categoriesSlice,
    stats: statsSlice,
});

export default rootReducer;