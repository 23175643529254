import React, { useEffect } from 'react';
import { useCollection } from '../../../context/collection/collection-provider';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchAll, fetchCursor } from '../../../store/tokens/tokens.slice';
import { OrderBy } from '../../../api/tokens/enums';
import { FetchParams, Token } from '../../../api/tokens/types';
import CollectionItemsFilter from './collection-items-filter';
import { Collection } from 'api/collections/types';
import { useTranslation } from 'react-i18next';
import CollectionItemCard from './collection-item-card';

interface CollectionItemsProps {
    collection: Collection;
}

const CollectionItems  : React.FC<CollectionItemsProps> = ({ collection }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { tokens = null, isLoading = true, errorMessage, orderBy, traits } = useSelector((state: RootState) => state.tokens);
    
    useEffect(() => {
        const params: FetchParams = {
            top: 9,
            order_by: orderBy as OrderBy,
            traits
        };
        dispatch(fetchAll({
            collection_slug: collection?.slug || '',
            params
        }));
    }, [dispatch, collection, orderBy, traits]);

    const handleLoadMore = () => {
        if (tokens?.links?.next) {
            dispatch(fetchCursor({cursor: tokens.links.next}));
        }
    };

    if (!collection) return <div>Loading...</div>;
    
    return collection && (
        <>
        <section className="section pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <CollectionItemsFilter collection={collection} />
                    </div>

                    <div className="col-lg-9 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-1">
                        {!isLoading && tokens?.data && tokens?.data.length === 0 ? (
                            <>
                            <div className="content"><p className="text-muted">No Items.</p></div>
                            </>
                        ) : ''}
                        {tokens?.data.map((item:Token,index:number)=>{
                            return(
                            <CollectionItemCard key={index} token={item} collection={collection} />
                            )
                        })}

                        {isLoading && Array.from({ length: 3 }).map((_, index) => (
                            <div className="col mt-4 pt-2" key={index}>
                                <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                                    <div className="nft-image rounded-md position-relative overflow-hidden">
                                        <div className="placeholder-glow">
                                            <div className="placeholder" style={{ height: '229px', width: '100%' }}></div>
                                        </div>
                                    </div>
                                    <div className="card-body content position-relative p-0 mt-3">
                                        <div className="placeholder-glow">
                                            <div className="placeholder w-75 mb-2"></div>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="placeholder w-25"></div>
                                                <div className="placeholder w-25"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>

                        {tokens?.links && tokens.links.next && (
                            <div className="row justify-content-center mt-4">
                                <div className="col">
                                    <div className="text-center">
                                        <button onClick={handleLoadMore} className="btn btn-primary rounded-md">
                                            {isLoading ?
                                                <>
                                                <i className="uil uil-process mdi-spin me-1"></i> Loading...
                                                </>
                                                :
                                                <>
                                                Load More
                                                </>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        </>
    )
};

export default CollectionItems;