import React,{useState} from 'react'
import { Link } from 'react-router-dom'

import appImg from '../assets/images/app.png';
import playstore from '../assets/images/playstore.png';
import logo  from '../assets/images/logo-footer.webp';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { Category } from '../api/categories/types';

export default function Footer() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { categoriesMenuList, isLoadingMenu, errorMessageMenu } = useSelector((state: RootState) => state.categories);
    let [visible, setVisible] = useState(false)

    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop; 
        if (scrolled > 300){ 
          setVisible(true) 
        }  
        else if (scrolled <= 300){ 
          setVisible(false) 
        } 
      }; 
      
      const scrollToTop = () =>{ 
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
      }; 
      
      window.addEventListener('scroll', toggleVisible); 
  return (
    <>
    <footer className="bg-footer">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="footer-py-60 footer-border">
                        <div className="row">
                            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                <Link to="/" className="logo-footer">
                                    <img src={logo} alt="fv" className="img-fluid" />
                                </Link>
                                <p className="para-desc mb-0 mt-4">Buy, sell and discover exclusive digital assets by the top artists of NFTs world.</p>
                                <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4 ">
                                    <li className="list-inline-item lh-1"><Link to="#" className="rounded"><i className="uil uil-instagram"></i></Link></li>
                                    <li className="list-inline-item lh-1"><Link to="#" className="rounded"><i className="uil uil-tiktok"></i></Link></li>
                                    <li className="list-inline-item lh-1"><Link to="#" className="rounded"><i className="uil uil-linkedin"></i></Link></li>
                                </ul>
                                
                            </div>
                            
                            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Menu</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                {categoriesMenuList && categoriesMenuList.data.map((category:Category, index:number)=>(
                                    <li key={`cfoot-${category.id}`} className={index === 0 ? "" : "ms-0"}><Link to={`/category/${category.slug}`} className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {category.name}</Link></li>
                                ))}
                                </ul>
                            </div>
                            
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Community</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="https://www.bagheadlabs.io" target='_blank' className="text-foot"><i className="uil uil-angle-right-b me-1"></i> About Us</Link></li>
                                    <li className='ms-0'><Link to="#" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Blog</Link></li>
                                    <li className='ms-0'><Link to="#" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Terms & Conditions</Link></li>
                                    <li className='ms-0'><Link to="#" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Privacy Policy</Link></li>
                                    <li className='ms-0'><Link to="mailto:hi@bagheadlabs.io" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Contact</Link></li>
                                </ul>
                            </div>
        
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Newsletter</h5>
                                <p className="mt-4">Sign up and receive the latest tips via email.</p>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="foot-subscribe mb-3">
                                                <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                <div className="form-icon position-relative">
                                                    <i className="uil uil-envelope icons fs-5 mb-2"></i>
                                                    <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-grid">
                                                <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Subscribe"/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-py-30 footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <div>
                            <p className="mb-0">© {new Date().getFullYear()} Follovers. Design by <Link to="https://bagheadlabs.io" target="_blank" className="text-reset">Bagheadlabs</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <Link to="#" id="back-to-top" onClick={()=>scrollToTop()} className="back-to-top rounded-pill fs-5" style={{display: visible ? 'inline' : 'none'}}><i className="uil uil-arrow-up"></i></Link>
    </>
  )
}
