import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { formatBalance, shortenAddress } from "utils/helpers/formatHelper";
import { useBalance, useBlockNumber } from "wagmi";
import { useQueryClient } from '@tanstack/react-query' 

interface AddressDisplayProps {
  address: string;
}

export default function AddressDisplay({ address }: AddressDisplayProps) {
  const formattedAddress = address.startsWith("0x") ? address : `0x${address}`;
  const queryClient = useQueryClient();
  const { data: blockNumber } = useBlockNumber({ watch: true })
  const {data, isLoading, isPending, isSuccess, queryKey} = useBalance({
      address: formattedAddress as `0x${string}`,
  });
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey })
  }, [queryClient, blockNumber, queryKey])

  function copyToClipboard() {
    navigator.clipboard.writeText(address).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  }

  const replacer = (_: string, value: any) =>
    typeof value === 'bigint' ? value.toString() : value;

  const serializedData = JSON.stringify(data, replacer);

  // @debug
  // console.log('AddressDisplay useBalance data, isLoading, isPending, isSuccess', serializedData, isLoading, isPending, isSuccess);

  return (
    <>
    <div className="mt-2">
        <small className="text-start text-dark d-block fw-bold">Wallet:</small>
        <div className="d-flex justify-content-between align-items-center">
            <small id="myPublicAddress" className="text-muted">{shortenAddress(address)}</small>
            {copied ? (
              <Button variant="link" className="text-primary" data-tooltip-id="t-copy" data-tooltip-content="Copied!">
                  <span className="uil uil-check"></span>
              </Button>
            ) : (
              <Button variant="link" className="text-primary" onClick={copyToClipboard} data-tooltip-id="t-copy" data-tooltip-content="Copy">
                  <span className="uil uil-copy"></span>
              </Button>
            )}
            <Tooltip id="t-copy" />
        </div>
    </div>
    <div className="mt-2">
        <small className="text-dark">Balance:
          {data && isSuccess ? (
          <span className="text-primary fw-bold mx-1">{formatBalance(data.value, data.symbol, data.decimals)}</span>
          ) : (isLoading || isPending) && (
          <span className="text-primary fw-bold mx-1">loading...</span>
          )}
        </small>
    </div>
    </>
  );
}