import React from 'react';
import { Trait } from '../../../api/tokens/types';

interface ItemTraitProps {
    trait: Trait;
}

const ItemTrait : React.FC<ItemTraitProps> = ({ trait }) => {
    return trait && (
    <>
    <div className="card activity activity-primary rounded-md shadow p-4">
      <div className="row">
          <div className="col">
            <small className="mb-0 text-muted" style={{ textTransform: 'capitalize' }}>
            {trait.category.name}
            </small>
            <h6>{trait.value}</h6>
          </div>
      </div>
    </div>
    </>
    );
};

export default ItemTrait;