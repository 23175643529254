import { ActionReducerMapBuilder, AsyncThunk, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from 'store/store';
// import { consoleLog, getErrorSafely } from './commonFunctions';

export interface GenericSliceState {
    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;
}

type CaseAction<T, U, V> = (
    state: Draft<T>,
    action: PayloadAction<
        U,
        string,
        {
            arg: V;
            requestId: string;
            requestStatus: 'fulfilled' | 'pending' | 'rejected';
        },
        never
    >,
) => void;

type ExtractAsyncThunkReturn<P> = P extends AsyncThunk<infer T, any, any> ? T : never;
type ExtractAsyncThunkArg<P> = P extends AsyncThunk<any, infer T, any> ? T : never;

export function AddActionCases<
    T extends GenericSliceState,
    U extends AsyncThunk<any, any, AsyncThunkConfig>,
>(
    builder: ActionReducerMapBuilder<T>,
    action: U,
    onFulfilled?: CaseAction<T, ExtractAsyncThunkReturn<U>, ExtractAsyncThunkArg<U>>,
    onPending?: CaseAction<T, undefined, ExtractAsyncThunkArg<U>>,
    onRejected?: CaseAction<T, ExtractAsyncThunkReturn<U>, ExtractAsyncThunkArg<U>>,
    customSuffix = '',
) {
    builder.addCase(action.fulfilled, (state, action) => {
        (state as any)[`isLoading${customSuffix}`] = false;
        (state as any)[`errorMessage${customSuffix}`] = '';
        (state as any)[`errorCode${customSuffix}`] = null;
        if (onFulfilled) onFulfilled(state, action);
    });
    builder.addCase(action.pending, (state, action) => {
        (state as any)[`isLoading${customSuffix}`] = true;
        state.errorMessage = '';
        (state as any)[`errorCode${customSuffix}`] = null;
        if (onPending) onPending(state, action);
    });
    builder.addCase(action.rejected, (state, action) => {
        // consoleLog('rejected: ', JSON.stringify(action.payload));
        (state as any)[`isLoading${customSuffix}`] = false;
        (state as any)[`errorCode${customSuffix}`] = action.payload?.errorCode;
        (state as any)[`errorMessage${customSuffix}`] = action.payload?.errorMessage;
        // (state as any)[`errorMessage${customSuffix}`] = getErrorSafely(
        //   action.payload?.errorCode,
        //   action.payload?.message
        // );
        if (onRejected) onRejected(state, action);
    });
}

export function HandleAsyncActionError(error: any, thunkAPI: any) {
    if (!error.response) {
        return thunkAPI.rejectWithValue({ errorCode: error.name, errorMessage: error.message });
    }
    return thunkAPI.rejectWithValue({
        errorCode: error.response.data.errorCode,
        errorMessage: error.response.data.message,
    });
}
