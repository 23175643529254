import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, checkUsername } from '../../store/users/users.slice';
import { User } from 'api/users/types';
import { isEqual } from 'lodash';
import { AppDispatch, RootState } from 'store/store';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { setUser } from 'store/auth/auth.slice';
import { Spinner } from 'react-bootstrap';


interface AccountEditFormProps {
    user: User | null;
}

const AccountEditForm: React.FC<AccountEditFormProps> = ({ user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isLoading, errorMessage, isLoadingCheckUsername, checkedUsername } = useSelector((state: RootState) => state.users);

    const [formData, setFormData] = useState({
        username: '',
        name: '',
        short_description: '',
        bio: '',
        email: '',
    });
    const [isModified, setIsModified] = useState(false);

    useEffect(() => {
        if (user) {
            setFormData({
                username: user.username || '',
                name: user.name || '',
                short_description: user.short_description || '',
                bio: user.bio || '',
                email: user.email || ''
            });
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            const initialData = {
                username: user.username,
                name: user.name,
                short_description: user.short_description,
                bio: user.bio,
                email: user.email
            };
            setIsModified(!isLoadingCheckUsername && !isEqual(formData, initialData));
        }
    }, [formData, user, isLoadingCheckUsername]);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const normalizedValue = value.replace(/[^a-zA-Z0-9-]/g, '');
      setFormData((prev) => ({ ...prev, username: normalizedValue }));
    };

    useEffect(() => {
      const checkUsernameAvailability = async () => {
        if (formData.username) {
          try {
            dispatch(checkUsername({username: formData.username}));
          } catch (error) {
            console.error('Error checking username availability:', error);
          }
        }
      };
  
      const delayDebounceFn = setTimeout(() => {
        checkUsernameAvailability();
      }, 1000);
  
      return () => clearTimeout(delayDebounceFn);
    }, [formData.username, dispatch]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user?.id) {
          try {
              const updatedUser = await dispatch(updateUser({ userId: user.id, params: formData })).unwrap();
              const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
              const mergedUser = { ...currentUser, ...updatedUser };
              await dispatch(setUser(mergedUser));
              // localStorage.setItem('user', JSON.stringify(mergedUser));
              setIsModified(false);

              // @debug
              // console.log('mergedUser', mergedUser);

              enqueueSnackbar(t('account.messages.updated_success').replace(':attribute', t('account.labels.profile')));
          } catch (error) {
              // @debug
              // console.error('Failed to update user:', error);

              enqueueSnackbar(t('account.errors.updated_failed'), { variant: 'error' });
          }
        }
    };


    // @debug
    // console.log("isLoadingCheckUsername, checkedUsername ", isLoadingCheckUsername, checkedUsername);

  return (
    <form className="profile-edit" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 mb-4 position-relative">
          <label className="form-label h6">Display Name</label>
          <input
            name="username"
            type="text"
            className="form-control pe-5"
            placeholder="jd"
            value={formData.username}
            onChange={handleUsernameChange}
            required={true}
            maxLength={50}
            readOnly={isLoadingCheckUsername}
            autoComplete='off'
          />
          {formData.username && (
          <>
            <span className="position-absolute end-0 me-4" style={{ top: '42px',  }}>
              {isLoadingCheckUsername ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="fs-6" />
              ) : checkedUsername && checkedUsername.data && checkedUsername.data.available ? (
                <i className="uil uil-check fs-6 text-success"></i>
              ) : (
                <i className="uil uil-multiply fs-6 text-danger"></i>
              )}
            </span>
            
            {checkedUsername && checkedUsername.data && !checkedUsername.data.available && checkedUsername.data.suggestions && (
              <div className="mt-2 d-flex flex-wrap">
                {checkedUsername.data.suggestions.map((suggestion, index) => (
                  <button
                    key={index}
                    type="button"
                    className="btn btn-outline-secondary me-2 mb-2"
                    onClick={() => setFormData({ ...formData, username: suggestion })}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </>
          )}
        </div>
        <div className="col-12 mb-4">
          <label className="form-label h6">Name</label>
          <input
            name="name"
            type="text"
            className="form-control"
            placeholder="Jhon Doe"
            value={formData.name}
            onChange={handleChange}
            required={true}
            maxLength={100}
          />
        </div>
        
        <div className="col-12 mb-4">
          <label className="form-label h6">Short description</label>
          <textarea
            name="short_description"
            rows={3}
            className="form-control"
            placeholder="Artist, UX / UI designer, and Entrepreneur..."
            value={formData.short_description}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mb-4">
          <label className="form-label h6">Bio</label>
          <textarea
            name="bio"
            rows={3}
            className="form-control"
            placeholder="I'm a Digital Artist..."
            value={formData.bio}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mb-4">
          <label className="form-label h6">Email</label>
          <input
            name="email"
            type="email"
            className="form-control"
            placeholder="jd@example.com"
            value={formData.email}
            onChange={handleChange}
            maxLength={255}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button type="submit" className="btn btn-primary rounded-md" disabled={!isModified || isLoading}>
            {isLoading ? (
                <>
                {t('common.buttons.update.loading')}
                </>
            ):(
                <>
                {t('common.buttons.update.label')}
                </>
            )}
            
          </button>
        </div>
      </div>
    </form>
  );
};

export default AccountEditForm;