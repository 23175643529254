import React, { useEffect, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesMenu, setCategoriesMenuFromCache } from './store/categories/categories.slice';
import { AppDispatch, RootState } from './store/store';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { FiltersYesNo } from './enums/filters';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style-dark.scss'
import './assets/css/materialdesignicons.min.css'

import Index from './pages/index';
import CategoryDetail from 'pages/category/category-detail';
import CollectionDetail from 'pages/collection/collection-detail';
import ProfileDetail from 'pages/profile/profile-detail';
import { Web3ModalProvider } from 'context/Web3ModalProvider';
import { SnackbarProvider } from 'notistack';
import { AppContextProvider } from 'context/AppContextProvider';
import PrivateRoute from 'components/private-route';
import AccountDetail from 'pages/account/account-detail';
import AccountNotification from 'pages/account/notifications';
import { PusherProvider } from 'context/PusherProvider';
import { updateAllCollections } from 'store/collections/collections.slice';
import ItemDetail from 'pages/collection/item-detail';
import { clearCache, getCacheItem, setCacheItem } from 'utils/helpers/cacheHelper';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n/i18n';

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // 24hs
    clearCache({ prefix: 'categoriesMenuList', duration: 24 * 60 * 60 * 1000 });

    const cachedCategories = getCacheItem('categoriesMenuList');
    if (cachedCategories) {
      dispatch(setCategoriesMenuFromCache(cachedCategories));
    } else {
      dispatch(fetchCategoriesMenu({ show_menu: FiltersYesNo.YES })).then((response) => {
        if (response.payload) {
          setCacheItem('categoriesMenuList', response.payload);
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    window.myDebugFunction = (data: any) => {
      console.log('Función ejecutada desde la consola', data);
      dispatch(updateAllCollections(data.data.collection));
    };
  }, [dispatch]);

  return (
    <SnackbarProvider maxSnack={3}>
      <Web3ModalProvider>
        <AppContextProvider>
          <PusherProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Index/>}/>
                    <Route path="/category/:slug" element={<CategoryDetail />} />
                    <Route path="/collection/:identifier" element={<CollectionDetail />} />
                    <Route path="/collection/:identifier/items/:token_identifier" element={<ItemDetail />} />
                    <Route path="/profile/:identifier" element={<ProfileDetail />}>
                    </Route>
                    <Route element={<PrivateRoute />}>
                      <Route path="/account">
                        <Route path="settings" element={<AccountDetail />} />
                        <Route path="settings/notifications" element={<AccountNotification />} />
                      </Route>
                    </Route>
                  </Routes>
                </BrowserRouter>
              </I18nextProvider>
            </Suspense>
          </PusherProvider>
        </AppContextProvider>
      </Web3ModalProvider>
    </SnackbarProvider>
  );
}

export default App;
