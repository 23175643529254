import React, { useEffect, useReducer, useState } from 'react';
import { useProfile } from '../../context/profile/profile-provider';
import Navbar from 'components/navbar';
import { Link, useLocation  } from 'react-router-dom';
import LazyImage from 'components/lazy-image';
import ExpandableText from 'components/expandable-text';
import ProfileCollections from './collections/profile-collections';
import { UserSocialPlatform } from 'api/users/types';
import ProfileNfts from './collections/profile-nfts';


const ProfileHeader = () => {
    let [activeTab, setActiveTab] = useState(1)
    const { user, authUser } = useProfile();
    const location = useLocation();
    const path = location.pathname;

    const isActiveNav = (segment:string) => {
        if (segment === 'details') {
            return !path.includes('/collections');
        }
        return path.includes(`/${segment}`);
    };

    if (!user){
        return <div>Loading...</div>;
    }

    return user && (
    <>
        <Navbar navlight={false}/>
        <section className="bg-creator-profile pb-0">
            <div className="container">
                {user.banner_image_permalink && (
                <div className="profile-banner">
                    <div className="position-relative d-inline-block">
                        <img src={user.banner_image_permalink} className="rounded-md shadow-sm img-fluid" id="profile-banner" alt={user.username}/>
                    </div>
                </div>
                )}

                <div className="row justify-content-center">
                    <div className="col">
                        <div className="text-center mt-n80">
                            <div className="profile-pic">
                                <div className="position-relative d-inline-block">
                                    <img src={user.profile_photo_url ?? `https://ui-avatars.com/api/?name=${user.username}`} className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" id="profile-image" alt={user.username}/>
                                </div>
                            </div>

                            <div className="content mt-3">
                                <h5 className="mb-3">{user.name}</h5>
                                {user.social_platforms && (
                                <div className="mt-4">
                                    <ul className="list-unstyled social-icon foot-social-icon">
                                        {user.social_platforms.map((item:UserSocialPlatform)=>{
                                            return (
                                            <li key={`sp-${item.code}`} className="list-inline-item lh-1">
                                                <a href={item.prepend_link + item.value} target="_blank" rel="noopener noreferrer" className="rounded" style={{backgroundColor:'#3c4858', borderColor: '#3c4858'}}>
                                                <i className={`uil uil-${(item.code === 'website' ? 'dribbble' : item.code)}`}></i>
                                                </a>
                                            </li>
                                        )})}
                                    </ul>
                                </div>
                                )}
                                {user.short_description && (
                                <h6 className="mt-3 mb-0">{user.short_description}</h6>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-100 mt-60">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs border-bottom">
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={()=>setActiveTab(1)}>Collections</button>
                            </li>
                            {authUser && user.id === authUser.id && (
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={()=>setActiveTab(3)}>My NFTs</button>
                            </li>
                            )}
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={()=>setActiveTab(2)}>About</button>
                            </li>
                        </ul>
                        <div className="tab-content mt-4 pt-2">
                            {activeTab === 1 && (
                            <div className="tab-pane fade show active">
                                <ProfileCollections user={user} />
                            </div>
                            )}
                            {authUser && user.id === authUser.id && activeTab === 3 && (
                            <div className="tab-pane fade show active">
                                <ProfileNfts user={authUser} />
                            </div>
                            )}
                            {activeTab === 2 && (
                            <div className="tab-pane fade show active">
                                <ExpandableText text={user.bio} limit={100} initiallyExpanded={true} />
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
};

export default ProfileHeader;