import React,{useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Outlet  } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { getByIdentifier } from '../../store/users/users.slice';
import ProfileProvider from '../../context/profile/profile-provider';
import ProfileHeader from '../../components/profile/profile-header';
import Footer from '../../components/footer';
import { useAppContext } from 'context/AppContextProvider';

const ProfileDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { user, isLoading, errorMessage } = useSelector((state: RootState) => state.users);
    const { identifier } = useParams<{ identifier: string }>();
    const { authUser } = useAppContext();
    
    useEffect(() => {
        if (identifier) {
            dispatch(getByIdentifier({ identifier: identifier }));
        }
    }, [dispatch, identifier]);

    return user && (
        <ProfileProvider user={user} authUser={authUser}>
            <ProfileHeader />
            <Outlet />
            <div className="mt-100"></div>
            <Footer />
        </ProfileProvider>
    )
};

export default ProfileDetail;