import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip';
import { useAppContext } from 'context/AppContextProvider';
import Navbar from 'components/navbar';
import { UserSocialPlatform } from 'api/users/types';
import bg from '../../assets/images/blog/single.jpg';
import Footer from 'components/footer';
import AccountEditForm from 'components/account/edit-form';
import AccountUploadForm from 'components/account/upload-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { updateUser } from 'store/users/users.slice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { setUser } from 'store/auth/auth.slice';
import AccountEditSocialConnectionForm from 'components/account/edit-social-connection-form';

const AccountDetail = () => {
    const { authUser } = useAppContext();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isLoading, errorMessage } = useSelector((state: RootState) => state.users);
    const [isUploading, setIsUploading] = useState(false);

    async function handleChangeBanner(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (file && authUser?.id) {
            setIsUploading(true);

            const formData = new FormData();
            formData.append('banner_image_url', file);

            const key = 'uploadBannerStatus';
            enqueueSnackbar(t('common.buttons.upload.loading'), { key, variant: 'info', persist: true });

            try {
                const updatedUser = await dispatch(updateUser({ userId: authUser.id, params: formData, method: 'post' })).unwrap();
                const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
                const mergedUser = { ...currentUser, ...updatedUser };
                await dispatch(setUser(mergedUser));
                // localStorage.setItem('user', JSON.stringify(mergedUser));
                closeSnackbar(key);
                enqueueSnackbar(t('account.messages.updated_success').replace(':attribute', t('account.labels.banner')), { key: new Date().getTime(), variant: 'success' });
            } catch (error) {
                console.error('Failed to update user:', error);
                closeSnackbar(key);
                enqueueSnackbar(t('account.errors.updated_failed'), { key: new Date().getTime(), variant: 'error' });
            } finally {
                setIsUploading(false);
            }
        }
    }

    async function handleChangeProfile(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (file && authUser?.id) {
            setIsUploading(true);

            const formData = new FormData();
            formData.append('profile_photo_path', file);

            const key = 'uploadProfileStatus';
            enqueueSnackbar(t('common.buttons.upload.loading'), { key, variant: 'info', persist: true });

            try {
                const updatedUser = await dispatch(updateUser({ userId: authUser.id, params: formData, method: 'post' })).unwrap();
                const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
                const mergedUser = { ...currentUser, ...updatedUser };
                await dispatch(setUser(mergedUser));
                // localStorage.setItem('user', JSON.stringify(mergedUser));
                closeSnackbar(key);
                enqueueSnackbar(t('account.messages.updated_success').replace(':attribute', t('account.labels.photo')), { key: new Date().getTime(), variant: 'success' });
            } catch (error) {
                console.error('Failed to update user:', error);
                closeSnackbar(key);
                enqueueSnackbar(t('account.errors.updated_failed'), { key: new Date().getTime(), variant: 'error' });
            } finally {
                setIsUploading(false);
            }
        }
    }
    
    return authUser && (
    <>
        <Navbar navlight={false}/>
        <section className="bg-creator-profile pb-0">
            <div className="container">
                <AccountUploadForm
                    imageSrc={authUser.banner_image_permalink ?? bg}
                    altText={authUser.username}
                    inputId="pro-banner"
                    labelFor="pro-banner"
                    tooltipId="help-banner"
                    tooltipContent="We recommend an image of at least 1200X200 px. GIFs work too."
                    imgClass="rounded-md shadow-sm img-fluid"
                    labelClass="icons position-absolute bottom-0 end-0"
                    containerClass="profile-banner"
                    onChange={handleChangeBanner}
                    isLoading={isUploading}
                    />
                
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="text-center mt-n80">
                        <AccountUploadForm
                            imageSrc={authUser.profile_photo_url ?? `https://ui-avatars.com/api/?name=${authUser.username}`}
                            altText={authUser.username}
                            inputId="pro-img"
                            labelFor="pro-img"
                            tooltipId="help-profile-photo"
                            tooltipContent="We recommend an image of at least 400X400 px. GIFs work too."
                            imgClass="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                            labelClass="icons position-absolute bottom-0 end-0"
                            containerClass="profile-pic"
                            onChange={handleChangeProfile}
                            isLoading={isUploading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <h5>You can set preferred display name, create your branded profile URL and manage other personal settings.</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-md-7 col-12 order-2 order-md-1 mt-4 pt-2">
                        <div className="rounded-md shadow">
                            <div className="p-4 border-bottom">
                                <h5 className="mb-0">Edit Profile:</h5>
                            </div>

                            <div className="p-4">
                                <AccountEditForm user={authUser} />
                            </div>
                        </div>
                        <div className="rounded-md shadow mt-4">
                            <div className="p-4 border-bottom">
                                <h5 className="mb-0">Social Connections:</h5>
                                <p>Help collectors verify your account by connecting social accounts</p>
                            </div>

                            <div className="p-4">
                                <AccountEditSocialConnectionForm user={authUser} />
                            </div>
                        </div>

                        <div className="rounded-md shadow mt-4">
                            <div className="p-4 border-bottom">
                                <h5 className="mb-0">Account Notifications:</h5>
                            </div>

                            <div className="p-4">
                                <div className="d-flex justify-content-between pb-4">
                                    <h6 className="mb-0">When someone mentions me</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti1"/>
                                        <label className="form-check-label" htmlFor="noti1"></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-4 border-top">
                                    <h6 className="mb-0">When someone follows me</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti2"/>
                                        <label className="form-check-label" htmlFor="noti2"></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-4 border-top">
                                    <h6 className="mb-0">When shares my activity</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti3"/>
                                        <label className="form-check-label" htmlFor="noti3"></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-4 border-top">
                                    <h6 className="mb-0">When someone messages me</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti4"/>
                                        <label className="form-check-label" htmlFor="noti4"></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="button" name="update" className="btn btn-primary rounded-md" disabled={true}>Coming soon</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-md shadow mt-4">
                            <div className="p-4 border-bottom">
                                <h5 className="mb-0">Marketing Notifications:</h5>
                            </div>

                            <div className="p-4">
                                <div className="d-flex justify-content-between pb-4">
                                    <h6 className="mb-0">There is a sale or promotion</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti5"/>
                                        <label className="form-check-label" htmlFor="noti5"></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-4 border-top">
                                    <h6 className="mb-0">Company news</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti6"/>
                                        <label className="form-check-label" htmlFor="noti6"></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-4 border-top">
                                    <h6 className="mb-0">Weekly jobs</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti7"/>
                                        <label className="form-check-label" htmlFor="noti7"></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between py-4 border-top">
                                    <h6 className="mb-0">Unsubscribe News</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="noti8"/>
                                        <label className="form-check-label" htmlFor="noti8"></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="button" name="update" className="btn btn-primary rounded-md" disabled={true}>Coming soon</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-md shadow mt-4">
                            <div className="p-4 border-bottom">
                                <h5 className="mb-0 text-danger">Delete Account:</h5>
                            </div>

                            <div className="p-4">
                                <h6 className="mb-0">Do you want to delete the account? Please press below "Delete" button</h6>
                                <div className="mt-4">
                                    <button className="btn btn-danger" disabled={true}>Delete Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </>
    )
};

export default AccountDetail;