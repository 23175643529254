import { Link } from 'react-router-dom'
import Navbar from 'components/navbar';
import React, { useEffect } from 'react';
import bg1 from '../../assets/images/bg/03.jpg'
import BestInfluencers from 'components/creater/best-influencers';
import { DisplayThemeProps } from 'interfaces/category/DisplayThemeProps';
import CategoryCollection from 'components/collection/category-collection';
import Footer from 'components/footer';

const DisplayTheme3: React.FC<DisplayThemeProps> = (props) => {
    return (
        <>
        <Navbar navlight={true}/>
        <section className="bg-half-170 d-table w-100 position-relative">
            <video autoPlay loop muted className="w-100 h-100 position-absolute top-0 start-0" style={{ objectFit: 'cover', zIndex: -1 }}>
                <source src="https://res.cloudinary.com/dwzrr6u1q/video/upload/f_auto:video,q_auto/v1/theme/assets/videos/x46inta2bcunics7tldf" type="video/webm" />
                Your browser does not support the video tag.
            </video>
        </section>
        <div className="position-relative">            
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <BestInfluencers params={{
                category_slug: props.category.slug
            }} />
            <CategoryCollection params={{
                category_slug: props.category.slug,
                collection_fields: 'owner,categories,stages,contracts'
            }} />
        </section>
        <Footer/>
        </>
    );
};

export default DisplayTheme3;