import React, { useEffect, useState } from 'react';
import { Collection } from '../../api/collections/types';
import CollectionQuantitySelector from './collection-quantity-selector';
import { getCurrentStage } from 'utils/helpers/collectionHelper';
// import { useAccount } from 'wagmi';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useAppContext } from 'context/AppContextProvider';
import { useTranslation } from 'react-i18next';

interface CollectionMintProps {
    collection: Collection;
}

const CollectionMint : React.FC<CollectionMintProps> = ({ collection }) => {
    // const { isConnected, address, chainId } = useAccount()
    const { t } = useTranslation();
    const { authUser } = useAppContext();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const stage = getCurrentStage(collection?.stages || [], userTimeZone);
    const collectionContract = collection.contracts ? collection.contracts[0] : null;

    let [quantity, setQuantity] = useState<number>(0);
    let [totalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        if (stage) {
            setQuantity(1);
            setTotalPrice(parseFloat(stage.sale_price));
        }
    }, [stage]);
    
    if (!stage || !collectionContract) return null;
    
    // setQuantity(1);
    // setTotalPrice(parseFloat(stage.sale_price));

    const onChange = (value: number) => {
        //// @debug
        // console.log('onChange value', value);

        setQuantity(value);
        const calculatedPrice = parseFloat(stage.sale_price) * value;
        setTotalPrice(parseFloat(calculatedPrice.toFixed(5)));
    };

    const projectId = process.env.REACT_APP_CROSSMINT_PROJECT_ID || '';
    const collectionId = collectionContract.cm_collection_id || '';
    const environment = collectionContract.chain
        ? collectionContract.chain.network === 'mainnet'
        ? 'production'
        : 'staging'
        : process.env.REACT_APP_CROSSMINT_ENVIRONMENT || 'staging';

    // @debug
    // console.log('CollectionMint authUser', authUser);
    // console.log('CollectionMint stage', stage);
    // console.log('CollectionMint totalPrice, quantity:', totalPrice, quantity);
    // console.log('CollectionMint projectId, collectionId, environment', projectId, collectionId, environment);

    // row justify-content-center
    return (
        <div className="row">
            <div className="col-auto text-center mt-4 pt-2">
                <div className="d-flex justify-content-center align-items-center">
                    <CollectionQuantitySelector min={1} max={collectionContract.total_available} onChange={onChange} />
                    <CrossmintPayButton
                        collectionId={collectionId}
                        projectId={projectId}
                        environment={environment}
                        mintConfig={{"totalPrice":totalPrice.toString(),"_qty":quantity.toString()}}
                        checkoutProps={{"paymentMethods":["ETH"]}}
                        disabled={authUser === null || collectionContract.total_available === 0}
                        mintTo={authUser && authUser.wallets ? authUser.wallets[0].wallet_address : ''}
                        whPassThroughArgs={{
                            user_id: authUser?.id,
                        }}
                        className="xmint-btn btn btn-primary ms-4"
                        getButtonText={(connecting, paymentMethod) =>
                            connecting ? t('common.buttons.buy.loading') : t('common.buttons.buy.label') // `Pay with ${paymentMethod}`
                        }
                    />
                    
                </div>
            </div>
            
            {/* totalPrice: {totalPrice.toString()}
            <br />
            qty: {quantity.toString()}
            <br />
            mintTo: {authUser && authUser.wallets ? authUser.wallets[0].wallet_address : ''}
            <br />
            {collection.contracts && (
                <>
                    <br />
                    total_minted: {collection.contracts[0].total_minted}
                    <br />
                    total_available: {collection.contracts[0].total_available}
                    <br />
                    is_soldout: {collection.contracts[0].is_soldout ? 'Yes' : 'No'}
                </>
            )} */}
            
        </div>
    );
};

export default CollectionMint;