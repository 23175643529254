import React from 'react';
import { format as formatDate, isAfter, isBefore, isWithinInterval } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Collection } from '../../api/collections/types';
import { formatSalePrice } from 'utils/helpers/formatHelper';

interface CollectionMintScheduleProps {
    collection: Collection;
}

const CollectionMintSchedule : React.FC<CollectionMintScheduleProps> = ({ collection }) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const stages = collection.stages || [];
    const sortedStages = [...stages].sort((a, b) => {
        return new Date(b.start_at).getTime() - new Date(a.start_at).getTime();
    });

    return sortedStages && (
        <>
          {sortedStages.map((stage, index) => {
            const startAt = new Date(stage.start_at);
            const endAt = stage.end_at ? new Date(stage.end_at) : null;
            const now = new Date();
    
            const startAtUserTime = toZonedTime(startAt, userTimeZone);
            const endAtUserTime = endAt ? toZonedTime(endAt, userTimeZone) : null;
    
            const isActive = isWithinInterval(now, { start: startAt, end: endAt || new Date(9999, 0, 1) });
            const hasEnded = endAt && isAfter(now, endAt);
            const notStarted = isBefore(now, startAt);

            const formattedDate = formatDate(startAtUserTime, 'MMMM d, yyyy h:mm a');
            const gmtOffset = formatDate(startAtUserTime, 'x');
    
            // @debug
            // console.log('CollectionMintSchedule stage', stage);
            // console.log('CollectionMintSchedule isActive, hasEnded, notStarted', isActive, hasEnded, notStarted);

            return (
              <div key={index} className="card activity activity-primary rounded-md shadow p-4 mb-4">
                <div className="row">
                    <div className="col-md-5">
                        <h6>{stage.name}
                            {notStarted && (
                                <>
                                &nbsp;-&nbsp;<small className="text-muted">UPCOMING</small>
                                </>
                            )}
                            {hasEnded && (
                                <>
                                &nbsp;-&nbsp;<small className="text-muted">ENDED</small>
                                </>
                            )}
                        </h6>
                        <small className={`mb-0 ${isActive ? 'fw-bold' : 'text-muted'}`}>{formatSalePrice(stage.sale_price)}</small>
                    </div>
                    
      
                  <div className="col-md-7">
                      <h6>Schedule</h6>
                      <small className="mb-0 text-muted">
                      {formattedDate} GMT{gmtOffset.slice(0, 1)}{parseInt(gmtOffset.slice(1, 3), 10)}
                      </small>
                  </div>
                </div>
              </div>
            );
          })}
        </>
    );
};

export default CollectionMintSchedule;