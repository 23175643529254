import React,{useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Navigate  } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { FiltersYesNo } from '../../enums/filters';
import { fetchCategoriesMenu } from '../../store/categories/categories.slice';
import { Category } from 'api/categories/types';

const CategoryDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { categoriesMenuList = null, isLoadingMenu = true, errorMessageMenu } = useSelector((state: RootState) => state.categories);
    const { slug } = useParams<{ slug: string }>();
    const [category, setCategory] = useState<Category | null>(null);

    useEffect(() => {
        if (categoriesMenuList && slug) {
          const foundCategory = categoriesMenuList.data.find(cat => cat.slug === slug);
          setCategory(foundCategory || null);
        }
    }, [categoriesMenuList, slug]);
    
    if(categoriesMenuList && category){
        try {
            const ThemeComponent = require(`../../components/category/display-${category?.display_theme}`).default;
            return <ThemeComponent category={category} />;
        } catch (error) {
            return <Navigate to="/404" />;
        }
    }
};

export default CategoryDetail;