import { Link } from 'react-router-dom'
import Navbar from 'components/navbar';
import React, { useEffect } from 'react';
import bg1 from '../../assets/images/bg/04.jpg'
import BestInfluencers from 'components/creater/best-influencers';
import { DisplayThemeProps } from 'interfaces/category/DisplayThemeProps';
import CategoryCollection from 'components/collection/category-collection';
import Footer from 'components/footer';

const DisplayTheme4: React.FC<DisplayThemeProps> = (props) => {
    return (
        <>
        <Navbar navlight={true}/>
        <section className="bg-half-170 d-table w-100 position-relative">
            <video autoPlay loop muted className="w-100 h-100 position-absolute top-0 start-0" style={{ objectFit: 'cover', zIndex: -1 }}>
                <source src="https://res.cloudinary.com/dwzrr6u1q/video/upload/f_auto:video,q_auto/v1/theme/assets/videos/aqnmcpqj3krkv3gkyh1h" type="video/webm" />
                Your browser does not support the video tag.
            </video>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                        <h5 className="heading fw-semibold sub-heading text-white title-dark">&nbsp;</h5>
                        <p className="text-white-50 para-desc mx-auto mb-0">&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <BestInfluencers params={{
                category_slug: props.category.slug
            }} />
            <CategoryCollection params={{
                category_slug: props.category.slug,
                collection_fields: 'owner,categories,stages,contracts'
            }} />
        </section>
        <Footer/>
        </>
    );
};

export default DisplayTheme4;