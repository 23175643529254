import React, { useEffect, useState, useMemo  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

import { fetchMintingNowCollections } from '../../store/collections/collections.slice';
import { AppDispatch, RootState } from '../../store/store';
import { Collection } from '../../api/collections/types';
import { Category } from '../../api/categories/types';
import { OrderBy } from '../../api/collections/enums';
import { FiltersYesNo } from '../../enums/filters';
import fvLoadingImage from '../../assets/images/fv-loading.webp';
import LazyImage from '../lazy-image';
import { getNextStage } from 'utils/helpers/collectionHelper';
import { formatSalePrice } from 'utils/helpers/formatHelper';
import { useTranslation } from 'react-i18next';

interface Props {
    filter?: boolean;
}

const MintingNowCollection: React.FC<Props> = ({filter = true}) =>{
    const dispatch = useDispatch<AppDispatch>();
    const { mintingNowCollections, isLoadingMintingNow, errorMessageMintingNow } = useSelector((state: RootState) => state.collections);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchMintingNowCollections({
            minting_now: FiltersYesNo.YES,
            featured: FiltersYesNo.NO,
            order_by: OrderBy.RANDOM,
            collection_fields: 'owner,categories,stages,contracts'
        }));
    }, [dispatch]);

    useEffect(() => {
        if (mintingNowCollections?.data) {
            const allCategories: Category[] = [];
            mintingNowCollections.data.forEach((collection: Collection) => {
                collection.categories?.forEach((category: Category) => {
                    if (!allCategories.some(existingCategory => existingCategory.id === category.id)) {
                        allCategories.push(category);
                    }
                });
            });
            setCategories(allCategories);
        }
    }, [mintingNowCollections]);

    const filteredCollections = selectedCategory
        ? mintingNowCollections?.data.filter((collection: Collection) =>
            collection.categories?.some((category: Category) => category.id === selectedCategory.id)
          )
        : mintingNowCollections?.data;

    const matchCategory = (category: Category) => {
        setSelectedCategory(category || null);
    };
    
    if (errorMessageMintingNow) {
        return <div>Error: {errorMessageMintingNow}</div>;
    }


  return (
    <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
            <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-4">{t('collection.minting_now.labels.title')}</h4>
                    <p className="text-muted para-desc mb-0 mx-auto">{t('collection.minting_now.labels.subtitle')}</p>
                </div>
            </div>
        </div>
        {filter === true && (
            <div className="row justify-content-center mt-4 mb-5">
                <div className="col filters-group-wrap">
                    <div className="filters-group">
                        <ul className="container-filter mb-0 categories-filter text-center list-unstyled filter-options">
                            <li className={`list-inline-item categories position-relative text-dark ${selectedCategory === null ? 'active' : ''}`} onClick={() => setSelectedCategory(null)}><i className="uil uil-browser"></i> All</li>
                            {[...categories].map((category:Category) => (
                                <li key={`${'c-' + category.id}`} className={`list-inline-item categories position-relative text-dark ${selectedCategory?.id === category.id ? 'active' : ''}`} onClick={() => matchCategory(category)}>
                                    <i className="uil uil-tag"></i> {category.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )}
        <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4" id="grid">
        {filteredCollections && filteredCollections.map((item:Collection)=>{
            const stage = getNextStage(item?.stages || [], userTimeZone);
            const contract = item?.contracts && item.contracts.length > 0 ? item.contracts[0] : null;
            return(
                <div className="col picture-item" key={`${'mn-' + item.id}`}>
                    <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                        <div className="d-flex align-items-center">
                            <div className="img-group">
                                <Link to={`/profile/${item.owner.username}`} className="user-avatar">
                                    <img src={item.owner.profile_photo_url} alt={item.owner.username} className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"/>
                                </Link>
                            </div>
                            <div className="ms-3">
                                <h6 className="mb-0"><Link to={`/profile/${item.owner.username}`} className="text-dark name">@{item.owner.username}</Link></h6>
                            </div>
                        </div>

                        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                            <Link to={`/collection/${item.slug}`}><LazyImage src={item.logo_permalink} className="img-fluid" alt={item.slug}/></Link>
                            
                            {/* {item.categories?.map((category: Category) => category.name).join(', ') || 'No categories available'} */}
                            {item.categories && item.categories.length > 0 && (
                                <div className="position-absolute top-0 start-0 m-2">
                                    {item.categories.map((category: Category) => (
                                    <Link key={category.id} to={`/category/${category.slug}`} className="badge badge-link bg-primary me-1">
                                        {category.name}
                                    </Link>
                                    ))}
                                </div>
                            )}
                            <div className="position-absolute top-0 end-0 m-2">
                                <Link to={`/collection/${item.slug}`} className="btn btn-pills btn-icon"><i className="uil uil-shopping-cart-alt"></i></Link>
                            </div>
                        </div>

                        <div className="card-body content position-relative p-0 mt-3">
                            <Link to={`/collection/${item.slug}`} className="title text-dark h6">{item.name}</Link>

                            {contract?.is_soldout ? (
                                <div className="d-flex justify-content-between mt-2">
                                    <small className="rate fw-bold">{t('collection.contracts.labels.soldout')}</small>
                                </div>
                            ) : stage && (
                            <div className="d-flex justify-content-between mt-2">
                                <small className="rate fw-bold">{formatSalePrice(stage.sale_price)}</small>
                                {contract && (
                                    <small className="text-dark fw-bold">{t('collection.contracts.labels.total_minted_out_of_total_supply').replace(':total_minted', contract.total_minted.toString()).replace(':total_supply', contract.total_supply.toString())}</small>
                                )}
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            )
        })}

        {isLoadingMintingNow && Array.from({ length: 4 }).map((_, index) => (
            <div className="col picture-item" key={`mn-${index}`}>
                <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                    <div className="d-flex justify-content-between">
                        <div className="img-group placeholder-glow">
                            <div className="placeholder rounded-circle avatar avatar-sm-sm img-thumbnail border-0 shadow-sm"></div>
                        </div>
                    </div>

                    <div className="nft-image rounded-md mt-3 position-relative overflow-hidden placeholder-glow">
                        <div className="placeholder w-100" style={{ height: '200px' }}></div>
                    </div>

                    <div className="card-body content position-relative p-0 mt-3 placeholder-glow">
                        <div className="placeholder w-75 mb-2"></div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="placeholder w-25"></div>
                            <div className="placeholder w-25"></div>
                        </div>
                    </div>
                </div>
            </div>
        ))}

        </div>
    </div>
  )
}

export default MintingNowCollection;