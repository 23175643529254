import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig, RootState } from '../store';
import { Token, TokenListResponse, FetchParams } from '../../api/tokens/types';

import {
    AddActionCases,
    GenericSliceState,
    HandleAsyncActionError,
} from '../../utils/helpers/reduxToolkitHelper';
import { OrderBy } from 'api/tokens/enums';

export interface tokensState extends GenericSliceState {
    tokens: TokenListResponse | null;
    token: Token | null;
    orderBy: string;
    traits: string[];

    isLoading: boolean;
    errorCode: string | null;
    errorMessage: string;

    isLoadingT: boolean;
    errorCodeT: string | null;
    errorMessageT: string;
}

export const initialState: tokensState = {
    tokens: null,
    token: null,
    orderBy: OrderBy.NAME,
    traits: [],

    isLoading: false,
    errorCode: null,
    errorMessage: '',

    isLoadingT: false,
    errorCodeT: null,
    errorMessageT: '',
};

// ------------- //
// Async Actions //
// ------------- //

export const getByIdentifier = createAsyncThunk<Token, { collection_slug: string, identifier: string, params?: FetchParams }, AsyncThunkConfig>(
    'tokens/getByIdentifier',
    async ({ collection_slug, identifier, params }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.tokens.getByIdentifier(collection_slug, identifier, params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchAll = createAsyncThunk<TokenListResponse, { collection_slug: string, params: FetchParams }, AsyncThunkConfig>(
    'tokens/fetchAll',
    async ({ collection_slug, params }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.tokens.fetchAll(collection_slug, params);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

export const fetchCursor = createAsyncThunk<TokenListResponse, { cursor: string }, AsyncThunkConfig>(
    'tokens/fetchCursor',
    async ({ cursor }, thunkAPI) => {
        try {
            const result = await thunkAPI.extra.tokens.fetchCursor(cursor);
            return result;
        } catch (err: any) {
            return HandleAsyncActionError(err, thunkAPI);
        }
    },
);

// ----- //
// Slice //
// ----- //

export const tokensSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        // -------------- //
        // Sync Reducers //
        // -------------- //

        clearTokens: (state, action: PayloadAction<void>) =>
            Object.assign(state, initialState),

        setOrderBy: (state, action: PayloadAction<string>) => {
            state.orderBy = action.payload;
        },
        setTraits: (state, action: PayloadAction<string[]>) => {
            state.traits = action.payload;
        },
    },
    extraReducers: (builder) => {
        // -------------- //
        // Async Reducers //
        // -------------- //

        AddActionCases(
            builder,
            getByIdentifier,
            (state, action) => {
                state.token = action.payload;
            },
            (state, action) => {
                state.token = null;
            },
            (state, action) => {},
            'T'
        );
        
        AddActionCases(
            builder,
            fetchAll,
            (state, action) => {
                state.tokens = action.payload;
            },
            (state, action) => {
                state.tokens = null;
            },
            (state, action) => {},
        );

        AddActionCases(
            builder,
            fetchCursor,
            (state, action) => {
                if (state.tokens) {
                    state.tokens.data = [...state.tokens.data, ...action.payload.data];
                    state.tokens.links = action.payload.links;
                    state.tokens.meta = action.payload.meta;
                } else {
                    state.tokens = action.payload;
                }
            },
            (state, action) => {},
            (state, action) => {},
        );

    },
});

// ------------ //
// Sync Actions //
// ------------ //
export const { clearTokens, setOrderBy, setTraits } = tokensSlice.actions;

export const tokensSelector = (state: RootState) => state.tokens;
export default tokensSlice.reducer;
