import React from 'react';
import fvLoadingImage from '../../assets/images/fv-loading.webp';

interface ImageProps {
  src: string;
  alt: string;
  className: string;
}

const generateSrcSet = (src: string, widths: number[]): string => {
    return widths.map(width => `${src}?auto=format&dpr=1&w=${width} ${width}w`).join(', ');
};

const LazyImage: React.FC<ImageProps> = ({ src, alt, className }) => {
    const srcSet = generateSrcSet(src, [128, 256, 384, 512, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]);
    return(
        <img
            alt={alt}
            src={`${src}?a=1`}
            sizes="(max-width: 20rem) 100vw, (max-width: 30rem) 50vw, (max-width: 50rem) 33.33vw, (max-width: 60rem) 25vw, (max-width: 1024px) 20vw, (max-width: 70rem) 33.33vw, (max-width: 90rem) 25vw, (max-width: 110rem) 20vw, (max-width: 130rem) 16.67vw, (max-width: 150rem) 14.29vw, 12.5vw"
            srcSet={srcSet}
            loading="lazy"
            decoding="async"
            className={className}
            />
    )
};

export default LazyImage;