import React,{useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link  } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { fetchAll, getByIdentifier } from '../../store/tokens/tokens.slice';
import Footer from '../../components/footer';
import Navbar from 'components/navbar';
import ExpandableText from 'components/expandable-text';
import { shortenAddress, shortenText } from 'utils/helpers/formatHelper';
import { FetchParams, Token, Trait } from 'api/tokens/types';
import ItemTrait from 'components/collection/items/item-trait';
import { OrderBy } from 'api/tokens/enums';
import CollectionItemCard from 'components/collection/items/collection-item-card';
import cart from '../../assets/images/svg/products-to-cart-or-basket.svg'

const ItemDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { token, isLoadingT = true, tokens, isLoading = true } = useSelector((state: RootState) => state.tokens);
    const { identifier, token_identifier } = useParams<{ identifier: string, token_identifier: string }>();
    let [activeTab, setActiveTab] = useState(1)
    
    useEffect(() => {
        if (identifier && token_identifier) {
            dispatch(getByIdentifier({ collection_slug: identifier, identifier: token_identifier, params: {token_fields:'collection', collection_fields:'contracts,token_standard'}}));
            // setActiveTab(1);
        }
    }, [dispatch, identifier, token_identifier]);

    useEffect(() => {
        if (token && token.collection) {
            const params: FetchParams = { 
                order_by: OrderBy.NAME,
                except_ids: token.id,
                top: 3
            };
            dispatch(fetchAll({collection_slug: token.collection.slug, params}));
        }
    }, [dispatch, token]);

    const contract = (token?.collection?.contracts && token?.collection?.contracts.length > 0 ? token?.collection?.contracts[0] : null);

    return (
        <>
        <Navbar navlight={false}/>
        <section className="bg-item-detail d-table w-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        {isLoadingT ? (
                        <div className="sticky-bar placeholder-glow">
                            <div className="placeholder w-100" style={{ height: '736px' }}></div>
                        </div>
                        ) : (
                        <div className="sticky-bar">
                            <img src={token?.display_image_permalink} className="img-fluid rounded-md shadow" alt={token?.name}/>
                        </div>
                        )}
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="ms-lg-5">
                            {isLoadingT ? (
                            <div className="title-heading placeholder-glow">
                                <h6 className="placeholder text-muted fw-normal mb-3" aria-hidden="true">Loading...</h6>
                                <br />
                                <h4 className="placeholder h3 fw-bold mb-0" aria-hidden="true">Loading...</h4>
                            </div>
                            ) : (
                            <div className="title-heading">
                                <Link to={`/collection/${identifier}`}>
                                    <h6 className="text-muted fw-normal mb-3">{token?.collection?.name}</h6>
                                </Link>
                                <h4 className="h3 fw-bold mb-0">{token?.name}</h4>
                            </div>
                            )}

                            <div className="row mt-4 pt-2">
                                <div className="col-12">
                                    <ul className="nav nav-tabs border-bottom">
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={()=>setActiveTab(1)}>Overview</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={()=>setActiveTab(2)}>Properties</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={()=>setActiveTab(3)}>About</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={()=>setActiveTab(4)}>Details</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content mt-4 pt-2">
                                        {activeTab === 1 && (
                                            <div className="tab-pane fade show active">
                                                {isLoadingT ? (
                                                <p className="placeholder text-muted fw-normal mb-3" aria-hidden="true">
                                                    Loading...
                                                </p>
                                                ) : (
                                                <ExpandableText text={token?.description} limit={100} initiallyExpanded={true} />
                                                )}
                                            </div>
                                        )}
                                        {activeTab === 2 && (
                                            <div className="tab-pane fade show active">
                                                {isLoadingT ? (
                                                <p className="placeholder text-muted fw-normal mb-3" aria-hidden="true">
                                                    Loading...
                                                </p>
                                                ) : token && token.traits && token.traits.length > 0 ? (
                                                    <div className="row">
                                                        {token.traits.map((trait:Trait, index:number)=>{
                                                            return(
                                                            <div key={index} className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                                                                <ItemTrait trait={trait} />
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                ) : (
                                                    <p className="text-muted">Whoops!<br />Looks like the properties you were looking for wasn't found.</p>
                                                )}
                                            </div>
                                        )}
                                        {activeTab === 3 && (
                                            <div className="tab-pane fade show active">
                                                {isLoadingT ? (
                                                <p className="placeholder text-muted fw-normal mb-3" aria-hidden="true">
                                                    Loading...
                                                </p>
                                                ) : (
                                                <ExpandableText text={token?.collection?.description} limit={100} initiallyExpanded={true} />
                                                )}
                                            </div>
                                        )}
                                        {activeTab === 4 && (
                                            <div className="tab-pane fade show active">
                                                <div className="row mb-2">
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                        Contract Address
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12 text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : contract && (
                                                        <a href={contract.chain?.explorer_url + contract.address} className="text-primary" target="_blank" rel="noopener noreferrer">
                                                            {shortenAddress(contract.address || '')}
                                                        </a>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                        Token ID
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12 text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : (
                                                        <span className="text-muted">{shortenText(token?.identifier_number || '')}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                        Token Standard
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12 text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : contract && (
                                                        <span className="text-muted">{contract.token_standard?.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                        Chain
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12 text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : contract && (
                                                        <span className="text-muted">{contract.chain?.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section pt-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-2">
                            <h4 className="title mb-4">More From This Collection</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-1">
                        {token && tokens && (
                            tokens.data.length === 0 ? (
                                <>
                                <div className="col"></div>
                                <div className="col text-center">
                                    <img src={cart} className="img-fluid" alt="collections" />
                                    <div className="content mt-4">
                                        <h5 className="mb-4">No more items</h5>
                                        <p className="text-muted">
                                            Browse interesting photos in personalized collections and discover new and interesting recommendations along the way.
                                        </p>
                                    </div>
                                </div>
                                <div className="col"></div>
                                </>
                        ) : tokens.data.map((item: Token, index: number) => (
                                <CollectionItemCard key={index} token={item} collection={token.collection} />
                            ))
                        )}

                        {isLoading && Array.from({ length: 3 }).map((_, index) => (
                            <div className="col mt-4 pt-2" key={index}>
                                <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                                    <div className="nft-image rounded-md position-relative overflow-hidden">
                                        <div className="placeholder-glow">
                                            <div className="placeholder" style={{ height: '229px', width: '100%' }}></div>
                                        </div>
                                    </div>
                                    <div className="card-body content position-relative p-0 mt-3">
                                        <div className="placeholder-glow">
                                            <div className="placeholder w-75 mb-2"></div>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="placeholder w-25"></div>
                                                <div className="placeholder w-25"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        {!isLoading && token && (
                            <div className="mt-5 text-center">
                                <Link to={`/collection/${identifier}`} className="back-button btn btn-primary">
                                View collection
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <div className="mt-100"></div>
        <Footer />
        </>
    )
};

export default ItemDetail;