import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import { fetchSliders } from '../store/sliders/sliders.slice';
import { AppDispatch, RootState } from '../store/store';
import { Slider } from '../api/sliders/types';
import fvLoadingImage from '../assets/images/fv-loading.webp';

export default function SliderHome() {
    const dispatch = useDispatch<AppDispatch>();
    const { sliderList, isLoading, errorMessage } = useSelector((state: RootState) => state.sliders);

    useEffect(() => {
        dispatch(fetchSliders());
    }, [dispatch]);

    const settings = {
        container: '.tiny-slider',
        controls: false,
        lazyload: true,
        // controlsPosition: 'bottom',
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0// 12,
    };

    if (isLoading) {
        return (
            <div className="row" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="col text-center">
                    <img src={fvLoadingImage} className="img-fluid coin-spin" alt="loading" />
                </div>
            </div>
        );
    }

    if (errorMessage) {
        return <div>Error: {errorMessage}</div>;
    }


    return sliderList && (
        <div className="row">
            <div className="col">
                <div className="tiny-slider">
                    <TinySlider settings={settings}>
                        {sliderList.data.map((item: Slider) => (
                            <div className="tiny-slide" key={`${'slider-' + item.id}`}>
                                <div className="card collections collection-primary">
                                    <div className="row g-2">
                                        <div className="col-12">
                                            {item.url ? (
                                                <a href={item.url} target='_blank' rel="noopener noreferrer" className="cursor-pointer">
                                                    <img
                                                    className={`img-fluid`}
                                                    src={item.image_desktop_url_permalink}
                                                    alt={item.name} />
                                                </a>
                                            ) : (
                                                <img
                                                    className={`img-fluid`}
                                                    src={item.image_desktop_url_permalink}
                                                    alt={item.name} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))} 
                    </TinySlider>
                </div>
            </div>
        </div>
    );
}