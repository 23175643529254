import React from 'react'
import { Link } from 'react-router-dom'

import Navbar from '../../components/navbar'
import BestInfluencers from '../../components/creater/best-influencers'
import Footer from '../../components/footer'
import SliderHome from '../../components/slider-home';
import FeaturedCollection from '../../components/collection/featured-collection'
import MintingNowCollection from '../../components/collection/minting-now-collection'

import community from '../../assets/images/svg/community.png'
import united from '../../assets/images/svg/united.png'
import TrendingCollection from 'components/collection/trending-collection'
import ComingSoonCollection from 'components/collection/coming-soon-collection'


export default function Index() {
    
  return (
    <>
    <Navbar navlight={true}/>

    <section>
        <SliderHome/> 
    </section>
   
    <section className="section">
        
        <BestInfluencers />
        
        <FeaturedCollection/> 
        
        <MintingNowCollection/>
        
        <TrendingCollection/>

        <div className="container-fluid mt-100 mt-60">
            <div className="row px-0">
                <div className="bg-half-100 bg-gradient-primary">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title text-white title-dark mb-4">Join the fastest growing Superex NFTs <br/> with more than 2000+ NFTs</h4>
                                    <p className="text-white-50 para-desc mb-0 mx-auto">We are a huge marketplace dedicated to connecting great artists of all Superex with their fans and unique token collectors!</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="card p-4 rounded-md shadow bg-white">
                                    <h4 className="mb-4">Join our community</h4>
                                    <p className="text-muted mb-0">We are a huge marketplace dedicated to connecting great artists of all Superex.</p>

                                    <div className="mt-3">
                                        <Link to="/aboutus" className="btn btn-link primary text-dark">Read More <i className="uil uil-arrow-right"></i></Link>
                                    </div>
                                    <div className="py-4"></div>
                                    <div className="position-absolute bottom-0 end-0">
                                        <img src={community} className="avatar avatar-medium opacity-05" alt=""/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="card p-4 rounded-md shadow bg-white">
                                    <h4 className="mb-4">Learn more about Superex</h4>
                                    <p className="text-muted mb-0">We are a huge marketplace dedicated to connecting great artists of all Superex.</p>

                                    <div className="mt-3">
                                        <Link to="/aboutus" className="btn btn-link primary text-dark">Read More <i className="uil uil-arrow-right"></i></Link>
                                    </div>
                                    <div className="py-4"></div>
                                    <div className="position-absolute bottom-0 end-0">
                                        <img src={united} className="avatar avatar-medium opacity-05" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ComingSoonCollection/>
    </section>
    <Footer/>

    </>
  )
}
